import React, { useState, createContext, useContext, useEffect } from 'react';
import './App.css';


import {FirstPage} from './FirstPage'
import {SecondPage} from './SecondPage'


const QuestionContext = createContext(null)

const App = ({year,month,day}) => {
    const [ page, setPage ] = useState({page:1, scoretable:null});
    const [ questions, setQuestions ] = useState([]);

    useEffect(() => {
        if (questions.length === 0) {
            if (year && month && day) {
                fetch(`/api/getarchivequestions?year=${year}&month=${month}&day=${day}`)
                    .then(response => response.json())
                    .then(json => setQuestions(json))
                    .catch(error => console.error(error));
            }
            else {
                fetch('/api/getquestions')
                    .then(response => response.json())
                    .then(json => setQuestions(json))
                    .catch(error => console.error(error));
            }
        }
    }, [questions.length])

    if (questions.length === 0)
        return null


    return (
        <QuestionContext.Provider value={{questions}}>
            <Home questions={questions} page={page} setPage={setPage}/>
        </QuestionContext.Provider>
    )
}


const Home = ({questions, page, setPage}) => {

    const selectPage = () => {
        if (page.page === 1)
            return <FirstPage questions={questions} page={page} setPage={setPage}/>
        return <SecondPage questions={questions} page={page} setPage={setPage}/>
    }

    return (
        selectPage()
    )
}


/*
const CorrectChoice = ({q, score}) => {

    const header = score === 100  ?  "Correct!" : (score === 50 ? "Partially right" : "Sorry, you are wrong")

    const getAnswers = () => {
        const answers = []
        for (let i=0; i<q.rightchoice.length; i++) {
            const label = q.rightchoice[i]
            const rightchoice = q.choices.find((ch) => {
                return ch.label === label
            })
            answers.push(
                <li className="d-flex mb-4 true">
                    <img src="/images/true.svg" alt="" className="me-2 flex-shrink-0"/>
                    {rightchoice.value}
                </li>
            )
        }
        return answers
    }
    return (
        <>
        <div className="row">
            <div className="col-md-7">
                <h3>{header}</h3>
                <ul>
                    {getAnswers()}
                </ul>
            </div>
        </div>
        </>
    )
}
*/



export default App



